// import React from "react"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { FaGithubSquare, FaShareSquare,FaWindowClose } from "react-icons/fa"
// import Image from "gatsby-image"
// import { Link } from "gatsby"
// import { Grid } from "@material-ui/core"

// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
// import "../assets/css/animate.css"


// const Project = ({ description, title, github, stack, url, image, index }) => {

//   const [open, setOpen] = React.useState(false);
//   const theme = useTheme();
//   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return(<>
//      <div class="card4">
//         <div class="image4">
//         <Image fluid={image.childImageSharp.fluid} className="project-img"/>
//         </div>
//         <div className="animate-para">
//           <p style={{color:"#fff",fontWeight:"bold"}}>{title}</p>
//         </div>
//         <div class="details4">
//           <div class="center4">
//           <h1>{title}<br/><span></span></h1>
//           <p></p>
//           <ul>
//               <li><a href="#"><FaGithubSquare className="project-icon" style={{color:"white"}}/></a></li>
//               <li><a href="#"><FaShareSquare className="project-icon" style={{color:"white"}}/></a></li>
              
//             </ul>
//             <div className="project-stack">
//          {stack.map((item) => {
//           return <span key={item.id}>{item.title}</span>
//         })}
//       </div>
//           </div>
            
//         </div>
//       </div>
//     </>
//   )

// }

// Project.prototype = {}
// export default Project

import React, { useState } from "react"
import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
import Image from "gatsby-image"
import { Link } from "gatsby"
// import  css2 from "../assets/css/style1.css"

const Project = ({ description, title, github, stack, url, image, index ,ani}) => {

  
  return <article className="project"> 

    <Image fluid={image.childImageSharp.fluid} className="project-img" alt="mp2it images"/>
    <div className="project-info" data-sal={ani} data-sal-delay="200"  data-sal-easing="ease" data-sal-duration="1000">
      <span className="project-number"></span>
      {/* 0{index + 1} */}
      <h3>{title}</h3>
      <p className="project-desc">
        {description}
      </p>
      {/* <div className="project-stack">
        {stack.map((item) => {
          return <span key={item.id}>{item.title}</span>
        })}
      </div> */}
      <div className="project-links" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
           <div>
           {/* <a href={github}>
             <FaGithubSquare className="project-icon"/>
           </a>
           <a href={url}>
             <FaShareSquare className="project-icon"/>
           </a> */}
           </div>
           <div>
           <Link to={url} className="btn3" >
       Read More
      </Link>
           </div>
      </div>
      
    </div>
  </article>
}

Project.prototype = {}
export default Project


