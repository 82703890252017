import React from "react"
import Title from "./Title"
import Project from "./Project"
import { Link } from "gatsby"

const Projects = ({ projects, title, showLink }) => {

  return <section className="section projects">
    <Title title="our products" />
    <div className="section-center projects-center"> 
     {
       projects.map((project,index)=>{

        let ani="";
        if(index%2==0)
        {
          ani="slide-left";
        }
        else{
          ani="slide-right";
        }

       return<Project key={project.id} index={index} ani={ani} {...project}></Project>
       })
     }
    </div>
    {
      showLink && (<Link to="/products/" className="btn center-btn" >
        Products
      </Link>
      )}
  </section>
}

export default Projects




// import React from "react"
// import Title from "./Title"
// import Project from "./Project"
// import { Link } from "gatsby"
// import Grid from "@material-ui/core/Grid"

// const Projects = ({ projects, title, showLink }) => {
//   return <section className="section projects">
//     <Title title="our products" />
//     <div className="section-center projects-center"> 
//     <Grid container justify="space-between" spacing={1}>
//      {
//        projects.map((project,index)=>{
//          return(
//            <>
           
//   <Grid item sm={3} xs={12} >
//   <Project key={project.id} index={index} {...project}></Project>
//   </Grid>
//            </>
//          )  
//        })
//      }
//      </Grid>
//     </div>
//     {
//       showLink && (<Link to="/projects" className="btn center-btn" >
//         Projects
//       </Link>
//       )}
//   </section>
// }

// export default Projects
