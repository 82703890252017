import React from "react"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import Seo from "../components/Seo"

import Layout2 from "../components/Layout2"

const ProjectsPage = ({data}) => {
  const {allStrapiProjects:{nodes:projects}}=data;
  return (
    <Layout2>
       <Seo title="Products" description="MP2IT PRODUCTS PAGE MP RESEARCH WORK MPRESEARCHWORK MPBS MP BUSINESS SOLUTION MPRW" />
      <section className="project-page">
         <Projects projects={projects} title="our products"/>
      </section>
    </Layout2>
  )
}

export const query = graphql`
  {
    allStrapiProjects(sort: {fields: sort_id}){
      nodes {
        github
        id
        description
        title
        url
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
  }
`

export default ProjectsPage
